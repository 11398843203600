<template>
  <div
    v-if="show"
    :class="[
      'search-hierarchy-title',
      {
        'search-hierarchy-title__center': notPriority,
        'search-hierarchy-title__top-style': topStyle,
      },
    ]"
  >
    {{ langText }}
  </div>
</template>

<script>
export default {
  name: 'SearchHierarchyTitle',
  props: {
    currentPage: {
      type: [Number, String],
      default: 1,
    },
    // 在第一页没有优先级1，用于判断文案
    notPriority: {
      type: Boolean,
      default: false,
    },
    recTipIndexNew: {
      type: Boolean,
      default: false,
    },
    topStyle: {
      type: Boolean,
      default: false,
    },
    // 搜索词
    searchWord: {
      type: String,
      default: () => '',
    },
    language: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      show: false,
    }
  },
  computed: {
    langText() {
      return this.recTipIndexNew ? this.language.SHEIN_KEY_PC_23580?.replace('{0}', `${this.searchWord}`)
        : this.notPriority
          ? this.language.SHEIN_KEY_PC_22759
          : this.language.SHEIN_KEY_PC_22760
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      if (!this.searchWord) return
      // 重新搜索的时候清空。
      const SHEIN_SEARCH_HIERARCHY_KEY = 'sheinSearchHierarchyTitleKey'
      let res = {}
      try {
        res = JSON.parse(sessionStorage.getItem(SHEIN_SEARCH_HIERARCHY_KEY))
      } catch (e) {
        console.error(e)
      }
      const { page, keywords } = res || {}

      const resetSearch = keywords && keywords !== this.searchWord // 二次搜索

      if (!res || (page && this.currentPage <= page) || resetSearch) {
        this.show = true
        sessionStorage.setItem(
          SHEIN_SEARCH_HIERARCHY_KEY,
          JSON.stringify({
            page: this.currentPage,
            keywords: this.searchWord,
          })
        )
      }
    },
  },
}
</script>

<style lang="less">
.search-hierarchy-title {
  margin-bottom: 16px;
  padding: 0 10px;
  width: 100%;
  height: 17px;
  line-height: 17px;
  font-size: 14px;
  color: #222;
  font-weight: bold;
  &__center {
    text-align: center;
    font-weight: normal;
  }
  &__top-style {
    margin-bottom: 12px;
  }
}
</style>
